import React from 'react'
import Navbar from './Navbar'
import Herosection from '../paths/Herosection'
import Info from '../paths/Info'
import Companies from '../paths/Companies'
import Testimonials from '../paths/Testimonial'
import StudentStories from './StudentStories'
import ServicesHome from './ServicesHome'
import CourseHome from './CourseHome'
import Slide from './Slide'
import Footer from './Footer'
import NewsHome from './NewsHome'

const Home = () => {
  return (
    <div>
        
        <Herosection/>
        <Info/>
        <Companies/>
        <Testimonials/>
        <StudentStories/>
        <ServicesHome/>
        <CourseHome/>
        <NewsHome/>
        <Slide/>
        
    </div>
  )
}

export default Home