import React from 'react';
import './Home.css'; 
import g1 from '../assets/g1.jpg';
import g2 from '../assets/g2.jpg';

const NewsHome = () => {
  // Sample data for news articles
  const articles = [
    {
      id: 1,
      title: 'New Product Launch',
      image: g1,
      snippet:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero sed urna ultrices, eget sollicitudin est tempor.',
      link: '#',
    },
    {
      id: 2,
      title: 'Tech Innovation Summit',
      image: g2,
      snippet:
        'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
      link: '#',
    },
    // Add more articles as needed
  ];

  return (
    <div className='new'>
    <div className="title">News</div>
    <div className="sub-title">To stay more informed.</div>
    <div className="news-container">
      {articles.map((article) => (
        <div key={article.id} className="news-article">
          <img src={article.image} alt={article.title} className="news-image" />
          <div className="news-details">
            <h3 className="news-title">{article.title}</h3>
            <p className="news-snippet">{article.snippet}</p>
            <a href={article.link} className="read-more">
              Read More
            </a>
          </div>
          
        </div>
        
      ))}
     
    </div>
    <button className='read'>Read more Articles</button>
    </div>
  );
};

export default NewsHome;
