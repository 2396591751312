import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Herosection from './paths/Herosection';
import Info from './paths/Info';
import Companies from './paths/Companies';

import Footer from './components/Footer';
import StudentStories from './components/StudentStories';
import ServicesHome from './components/ServicesHome';
import CourseHome from './components/CourseHome';
import NewsHome from './components/NewsHome';
import GalleryHome from './components/GalleryHome';
import OfferSection from './components/Offer';
import Slide from './components/Slide';
import About from './components/About';
import Testimonials from './paths/Testimonial';
import Home from './components/Home';
import Course from './components/Courses';
import Contact from './components/Contact';
import Services from './components/Services';
import News from './components/News';
import Article from './components/Articles';
import AdminCourseUpload from './components/AdminCourseUpload';

function App() {
  return (
    <div className="App">
      <Router>
        
        <Routes>
          <Route path="/" element={<Home/>} />
          
          <Route path='/about' element={<About/>} />
          <Route path='/course' element={<Course/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/services' element={<Services/>} />
          <Route path='/news' element={<News/>} />
          <Route path="/article/:id" component={Article} />
          <Route path="/up" element={<AdminCourseUpload/>}/>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
