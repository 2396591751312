import React, { useState } from 'react';
import './Testimonial.css'; // Assuming you have a CSS file for styling

const Testimonials = () => {
  // State to manage active card
  const [activeCard, setActiveCard] = useState('content1');

  // Testimonial data (you can fetch this from an API or use static data)
  const testimonials = [
    {
      id: 'content1',
      name: 'John Doe',
      role: 'Office Manager, ABC Security',
      testimonial:
        'ABC Security provided excellent bodyguard services to our office. Their guards were highly trained and professional. We felt safe and secure with them around. Their proactive approach and attention to detail impressed us. The guards were courteous and always on time. We highly recommend ABC Security for anyone looking for top-notch security services.',
      stars: 5,
    },
    {
      id: 'content2',
      name: 'Jane Smith',
      role: 'Principal, XYZ Schools',
      testimonial:
        'XYZ Schools contracted with DEF Security for our school premises. Their guards were vigilant and proactive. They ensured the safety of our students and staff. DEF Security has been instrumental in maintaining a secure environment. Their quick response to incidents and effective communication with school staff have made them a trusted partner in our community.',
      stars: 4,
    },
    {
      id: 'content3',
      name: 'Michael Johnson',
      role: 'Facility Manager, LMN Security Solutions',
      testimonial:
        'LMN Security Solutions has been our partner for several years. Their bodyguards are well-trained and reliable. They respond promptly to any security concerns. We have consistently received positive feedback from our employees regarding LMN Security’s presence and professionalism. They have played a crucial role in safeguarding our facilities.',
      stars: 4.5,
    },
  ];

  // Function to handle card click
  const handleCardClick = (id) => {
    setActiveCard(id);
  };

  return (
    <section>
      <h1>Testimonials</h1>
      <div className="content">
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className={`contentBox ${activeCard === testimonial.id ? 'active' : ''}`}
            id={testimonial.id}
          >
            <div className="text">
              <h2>{testimonial.role}</h2>
              <p>{testimonial.testimonial}</p>
              <div className="stars">
                {Array.from({ length: testimonial.stars }, (_, index) => (
                  <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#FFD700"
                    width="20px"
                    height="20px"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                  </svg>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="cards">
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className={`card ${activeCard === testimonial.id ? 'active' : ''}`}
            onClick={() => handleCardClick(testimonial.id)}
          >
            <img
              src={`https://randomuser.me/api/portraits/men/${Math.floor(
                Math.random() * 100
              )}.jpg`}
              alt=""
            />
            <div>
              <h3>{testimonial.name}</h3>
              <p>{testimonial.role}</p>
              <div className="stars">
                {Array.from({ length: testimonial.stars }, (_, index) => (
                  <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#FFD700"
                    width="20px"
                    height="20px"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                  </svg>
                ))}
              </div>
            </div>
            <div className="gradient"></div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
