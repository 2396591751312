import React, { useEffect } from 'react';
import Navbar from './Navbar';
import './About.css';
import img from '../assets/s2.jpg';
import guard from '../assets/security-guard-front-entrance-vertical-679x1024.jpg';
import pic from '../assets/pic.jpg';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 600, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function for animation
      once: false, // Whether animation should happen only once
    });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="about">
        <div className="about-container">
          <div className="about-top-section" data-aos="fade-up">
            <div className="about-title">Safeguard your business with Us</div>
            <div className="about-top-img">
              <img src={img} alt="Security Service" />
            </div>
          </div>
          <div className="our-story" data-aos="fade-up">
            <div className="our-story-left" data-aos="fade-right">
              <div className="our-story-title">OUR STORY</div>
              <div className="our-story-subtitle">We are a company that has over  <br /> 10 years of expertise</div>
              <div className="company-history">
                <p>
                  Fewa Securities is a leading security service provider with over a decade of experience in the industry. Our mission is to safeguard businesses and individuals by providing highly trained and professional security guards. We specialize in offering comprehensive security solutions tailored to meet the unique needs of our clients. At Fewa Securities, we understand the importance of security in today's world. Our services include 24/7 on-site security, event security, mobile patrols, and more. Our security guards are trained to handle various situations, ensuring the safety and protection of your assets. In addition to providing security services, we are also dedicated to training the next generation of security professionals. Our training programs are designed to equip individuals with the skills and knowledge needed to excel in the security industry. We offer comprehensive training that covers everything from basic security procedures to advanced tactical training.
                </p>
              </div>
            </div>
            <div className="our-story-right" data-aos="fade-left">
              <img src={guard} alt="Security Guard" />
            </div>
          </div>
          <div className="about-card" data-aos="fade-up">
            <div className="about-card-container">
              <div className="top-text-about">Work With Us</div>
              <div className="top-subtext-about">Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi perspiciatis atque ab debitis br voluptas facere asperiores dolor. Officiis, numquam autem.Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi perspiciatis atque ab debitis br voluptas facere asperiores dolor. Officiis, numquam autem.Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi perspiciatis atque ab debitis br voluptas facere asperiores dolor. Officiis, numquam autem.</div>
              <div className="action-button"><Link to='/contact' className='links'>Get In Touch</Link> </div>
            </div>
          </div>
          <div className="our-storys" data-aos="fade-up">
            <div className="our-story-left" data-aos="fade-right">
              <div className="our-story-title">Words From our Founder</div>
              <div className="our-story-subtitle">We strive for absolute<br /> protection of your property</div>
              <div className="company-history">
                <p>
                  Fewa Securities is a leading security service provider with over a decade of experience in the industry. Our mission is to safeguard businesses and individuals by providing highly trained and professional security guards. We specialize in offering comprehensive security solutions tailored to meet the unique needs of our clients. At Fewa Securities, we understand the importance of security in today's world. Our services include 24/7 on-site security, event security, mobile patrols, and more. Our security guards are trained to handle various situations, ensuring the safety and protection of your assets. In addition to providing security services, we are also dedicated to training the next generation of security professionals. Our training programs are designed to equip individuals with the skills and knowledge needed to excel in the security industry. We offer comprehensive training that covers everything from basic security procedures to advanced tactical training.
                </p>
              </div>
            </div>
            <div className="our-story-right" id='circle' data-aos="fade-left">
              <img src={pic} alt="Security Guard" />
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default About;
