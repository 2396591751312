import React, { useEffect } from 'react';
import banner2 from '../assets/banner2.png';
import Navbar from './Navbar';
import './About.css'
import school from '../assets/school.jpg';
import hospital from '../assets/hospital.jpg';
import resturant from '../assets/resturant.jpg';
import mall from '../assets/mall.jpg';
import events from '../assets/event.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 600, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function for animation
      once: false, // Whether animation should happen only once
    });
  }, []);
  return (
    <>
     <Navbar/>
     <div className="banner-container " >
        <img src={banner2} alt="" />
      </div>
    <div className='service'>
       
        
        
        <div className="service-container" data-aos="fade-up">
        <div className="our-storys">
            <div className="our-story-left">
              
              <div className="our-story-subtitle" data-aos="fade-up">For Schools</div>
              <div className="company-history">
                <p>
                  Fewa Securities is a leading security service provider with over a decade of experience in the industry. Our mission is to safeguard businesses and individuals by providing highly trained and professional security guards. We specialize in offering comprehensive security solutions tailored to meet the unique needs of our clients. At Fewa Securities, we understand the importance of security in today's world. Our services include 24/7 on-site security, event security, mobile patrols, and more. Our security guards are trained to handle various situations, ensuring the safety and protection of your assets. In addition to providing security services, we are also dedicated to training the next generation of security professionals. Our training programs are designed to equip individuals with the skills and knowledge needed to excel in the security industry. We offer comprehensive training that covers everything from basic security procedures to advanced tactical training.
                </p>
              </div>
            </div>
            <div className="our-story-right" id='circle'>
              <img src={school} alt="Security Guard" />
            </div>
          </div>
          
        </div>
        
      </div>
      <div className="service-container" data-aos="fade-up">
        <div className="our-storys">
            <div className="our-story-left">
              
              <div className="our-story-subtitle">For Resturants</div>
              <div className="company-history">
                <p>
                  Fewa Securities is a leading security service provider with over a decade of experience in the industry. Our mission is to safeguard businesses and individuals by providing highly trained and professional security guards. We specialize in offering comprehensive security solutions tailored to meet the unique needs of our clients. At Fewa Securities, we understand the importance of security in today's world. Our services include 24/7 on-site security, event security, mobile patrols, and more. Our security guards are trained to handle various situations, ensuring the safety and protection of your assets. In addition to providing security services, we are also dedicated to training the next generation of security professionals. Our training programs are designed to equip individuals with the skills and knowledge needed to excel in the security industry. We offer comprehensive training that covers everything from basic security procedures to advanced tactical training.
                </p>
              </div>
            </div>
            <div className="our-story-right" id='circle'>
              <img src={resturant} alt="Security Guard" />
            </div>
          </div>
          
        </div>
        
        <div className="service-container" data-aos="fade-up">
        <div className="our-storys">
            <div className="our-story-left">
              
              <div className="our-story-subtitle">For Events</div>
              <div className="company-history">
                <p>
                  Fewa Securities is a leading security service provider with over a decade of experience in the industry. Our mission is to safeguard businesses and individuals by providing highly trained and professional security guards. We specialize in offering comprehensive security solutions tailored to meet the unique needs of our clients. At Fewa Securities, we understand the importance of security in today's world. Our services include 24/7 on-site security, event security, mobile patrols, and more. Our security guards are trained to handle various situations, ensuring the safety and protection of your assets. In addition to providing security services, we are also dedicated to training the next generation of security professionals. Our training programs are designed to equip individuals with the skills and knowledge needed to excel in the security industry. We offer comprehensive training that covers everything from basic security procedures to advanced tactical training.
                </p>
              </div>
            </div>
            <div className="our-story-right" id='circle'>
              <img src={events} alt="Security Guard" />
            </div>
          </div>
          
        </div>
        
        <div className="service-container" data-aos="fade-up">
        <div className="our-storys">
            <div className="our-story-left">
              
              <div className="our-story-subtitle">For Hospitals</div>
              <div className="company-history">
                <p>
                  Fewa Securities is a leading security service provider with over a decade of experience in the industry. Our mission is to safeguard businesses and individuals by providing highly trained and professional security guards. We specialize in offering comprehensive security solutions tailored to meet the unique needs of our clients. At Fewa Securities, we understand the importance of security in today's world. Our services include 24/7 on-site security, event security, mobile patrols, and more. Our security guards are trained to handle various situations, ensuring the safety and protection of your assets. In addition to providing security services, we are also dedicated to training the next generation of security professionals. Our training programs are designed to equip individuals with the skills and knowledge needed to excel in the security industry. We offer comprehensive training that covers everything from basic security procedures to advanced tactical training.
                </p>
              </div>
            </div>
            <div className="our-story-right" id='circle'>
              <img src={hospital} alt="Security Guard" />
            </div>
          </div>
          
        </div>
        
        <div className="service-container" data-aos="fade-up">
        <div className="our-storys">
            <div className="our-story-left">
              
              <div className="our-story-subtitle">For Malls</div>
              <div className="company-history">
                <p>
                  Fewa Securities is a leading security service provider with over a decade of experience in the industry. Our mission is to safeguard businesses and individuals by providing highly trained and professional security guards. We specialize in offering comprehensive security solutions tailored to meet the unique needs of our clients. At Fewa Securities, we understand the importance of security in today's world. Our services include 24/7 on-site security, event security, mobile patrols, and more. Our security guards are trained to handle various situations, ensuring the safety and protection of your assets. In addition to providing security services, we are also dedicated to training the next generation of security professionals. Our training programs are designed to equip individuals with the skills and knowledge needed to excel in the security industry. We offer comprehensive training that covers everything from basic security procedures to advanced tactical training.
                </p>
              </div>
            </div>
            <div className="our-story-right" id='circle'>
              <img src={mall} alt="Security Guard" />
            </div>
          </div>
          
        </div>
        
      
    
  
    </>
  )
}

export default Services