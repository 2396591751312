import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar'; // Ensure this path is correct


const articles = [
  {
    id: 1,
    title: "Company's 12th Year Anniversary",
    content: `Fewa Securities is a leading security service provider with over a decade of experience in the industry...`,
  },
  {
    id: 2,
    title: "Career prospect in security field",
    content: `Fewa Securities is a leading security service provider with over a decade of experience in the industry...`,
  },
  {
    id: 3,
    title: "Why is security important",
    content: `Fewa Securities is a leading security service provider with over a decade of experience in the industry...`,
  },
  {
    id: 4,
    title: "Security Helps to build Trust",
    content: `Fewa Securities is a leading security service provider with over a decade of experience in the industry...`,
  },
  {
    id: 5,
    title: "Risk of being robbed reduced 70%",
    content: `Fewa Securities is a leading security service provider with over a decade of experience in the industry...`,
  },
];

const Article = () => {
  const { id } = useParams();
  const article = articles.find(article => article.id === parseInt(id));

  if (!article) {
    return <div>Article not found</div>;
  }

  return (
    <>
      <Navbar />
      <div className="article-container">
        <h1>{article.title}</h1>
        <p>{article.content}</p>
      </div>
    </>
  );
}

export default Article;
