import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './StudentStories.css';
import p1 from '../assets/p1.jpeg';
import p2 from '../assets/p2.jpeg';
import p3 from '../assets/p3.jpeg';

const StudentStories = () => {
  const reviews = [
    {
      id: 1,
      name: 'John Doe',
      title: 'Fantastic Learning Experience',
      review: 'This is a fantastic course!',
      story: 'I learned about security with Fewa Securities and it really helped me advance my career. The instructors were knowledgeable and the content was very relevant.',
      image: p1,
    },
    {
      id: 2,
      name: 'Jane Smith',
      title: 'Highly Recommend',
      review: 'I learned so much, highly recommend.',
      story: 'Fewa Securities provided me with the skills and knowledge I needed to excel in my job. The practical approach to learning made all the difference.',
      image: p2,
    },
    {
      id: 3,
      name: 'Sam Wilson',
      title: 'Great Instructors and Content',
      review: 'Great instructors and content!',
      story: 'The course was well-structured and the instructors were experts in the field. Thanks to Fewa Securities, I feel confident in my security skills.',
      image: p3,
    },
    // Add more reviews as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className='student-container'>
      <div className="student-title">
        Student Testimonials
      </div>
      <Slider {...settings}>
        {reviews.map((review) => (
          <div key={review.id} className='student-content'>
            <div className='student-review'>
              <img src={review.image} alt={review.name} className='student-image' />
              <div className='student-text'>
                <h3>{review.title}</h3>
                <h4 className='std-name'>{review.name}</h4>
                <p>{review.review}</p>
                <p>{review.story}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default StudentStories;
