import React, { useState, useEffect } from 'react';
import { Typewriter } from 'react-simple-typewriter';
import './Herosection.css';
import heroimg from '../assets/intro.jpg'; // Make sure you have an appropriate image
import blob1 from '../assets/blob1.svg'
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';

const Herosection = () => {
  const [showCursor, setShowCursor] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleTypingEnd = () => {
    setShowCursor(false); // Hide the cursor when typing ends
  };

  useEffect(() => {
    const fadeInImage = () => {
      setIsImageLoaded(true);
    };

    // Simulate a slight delay for demonstration purposes
    const delay = setTimeout(() => {
      fadeInImage();
    }, 500);

    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      <Navbar />
      <div className='hero-container'>
        <div className="hero-left">
          
          <span className="hero-title">
            Protecting{' '}
            <span className="text-color">
              <Typewriter
                words={['Access', 'Assets', 'Safety']}
                cursor={showCursor}
                cursorStyle={showCursor ? '' : 'none'} // Show cursor if showCursor is true
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
                onTypingEnd={handleTypingEnd} // Callback when typing animation ends
              />
            </span>{' '}
            is
          </span>
          <br />
          <span className="title-next-line">
            our <span className="text-color">mission</span>
          </span>
          <span className="hero-text">
            We provide security services to safeguard
            <br />
            to various organizations rnaging from
            <br />
            schools,events,office and more
          </span>
          <div className="hero-button">
            <Link to='/contact' className='link'>Get Started</Link>
            
          </div>
        </div>
        <div className={`hero-right ${isImageLoaded ? 'fade-in' : ''}`}>
          <div className="hero-image">
            <img src={heroimg} alt="Hero" onLoad={() => setIsImageLoaded(true)} />
          </div>
        </div>
       
      </div>
      
    </>
  );
}

export default Herosection;
