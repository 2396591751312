import React, { useEffect } from 'react';
import './CourseHome.css';
import s1 from '../assets/s1.jpg';
import services from '../assets/services.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const CourseHome = () => {
  useEffect(() => {
    AOS.init({
      duration: 600, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function for animation
      once: false, // Whether animation should happen only once
    });
  }, []);

  return (
    <div className="course-container">
      <div className="course-content" data-aos="fade-up">
        <img src={services} alt="" className='serv' />
        <div className="course-content-top">
          <div className="course-title-top">Our Services 🤝</div>
          <div className="course-duration">Services For Your Business</div>
          <div className="more">View More</div>
        </div>
        <div className="courses">
          <div className="course-img" data-aos="fade-right">
            <img src={s1} alt="" />
          </div>
          <div className="courses-info" data-aos="fade-left">
            <div className="course-title">Office Security 💼</div>
            <div className="course-description">We provide Security for different businesses</div>
          </div>
          <div className="lowe-desc" data-aos="fade-up">
            <div className="course-low-description">Our expertise and commitment to excellence make us a reliable choice for your business security needs.</div>
          </div>
        </div>
        <div className="courses">
          <div className="course-img" data-aos="fade-right">
            <img src={s1} alt="" />
          </div>
          <div className="courses-info" data-aos="fade-left">
            <div className="course-title">Office Security 💼</div>
            <div className="course-description">We provide Security for different businesses</div>
          </div>
          <div className="lowe-desc" data-aos="fade-up">
            <div className="course-low-description">Our expertise and commitment to excellence make us a reliable choice for your business security needs.</div>
          </div>
        </div>
        <div className="courses">
          <div className="course-img" data-aos="fade-right">
            <img src={s1} alt="" />
          </div>
          <div className="courses-info" data-aos="fade-left">
            <div className="course-title">Office Security 💼</div>
            <div className="course-description">We provide Security for different businesses</div>
          </div>
          <div className="lowe-desc" data-aos="fade-up">
            <div className="course-low-description">Our expertise and commitment to excellence make us a reliable choice for your business security needs.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseHome;
