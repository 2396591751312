import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
import Dropdown from './Dropdown';
import Button from '@mui/material/Button';
import logo from '../assets/logo.png'
import { motion } from 'framer-motion';
import { RxHamburgerMenu } from "react-icons/rx";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { Link } from 'react-router-dom';
import About from './About';

const Navbar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [isVerticalNavOpen, setIsVerticalNavOpen] = useState(false);
  const dropdownRef = useRef(null);
  const verticalNavRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleVerticalNav = () => {
    setIsVerticalNavOpen(!isVerticalNavOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
      if (verticalNavRef.current && !verticalNavRef.current.contains(event.target)) {
        setIsVerticalNavOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-brand">
          <img src={logo} alt="Atreya logo" id="atreya-logo" />
        </div>
        <div className="navbar-links">
          <div className="pages"><Link to='/'>Home</Link></div>
          <div className="pages"><Link to='/about'>About</Link></div>
          <div className="pages"><Link to='/services'>Services</Link></div>
          <div className="pages"><Link to='/course'>Course</Link></div>
          <div className="pages"><Link to='/news'>News</Link></div>
          
        </div>
        {!isMobile && (
          <Button
            variant="contained"
            className="contacts-button"
            sx={{
              backgroundColor: '#00',
              transition: '0.4s ease-in-out',
              '&:hover': {
                backgroundColor: '#007EAF',
                transition: '0.4s ease-in-out',
                transform: 'scale(1.02)',
              },
            }}
          >
            <Link to='/contact' className='link'>Contact Us</Link>
          </Button>
        )}
        <div className="hamburger-menu" onClick={toggleVerticalNav}>
          <RxHamburgerMenu id="hamburger" color='#7ab7fd' />
        </div>
      </nav>

      {isMobile && (
        <motion.div
          className="vertical-navbar"
          initial={{ x: '100%' }}
          animate={{ x: isVerticalNavOpen ? 10 : '100%' }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          ref={verticalNavRef}
        >
          <div className="vertical-navbar-close" onClick={toggleVerticalNav}>
            <IoMdClose color='#FFFF'/>
          </div>
          <div className="vertical-navbar-links">
            <div className="pages" onClick={toggleVerticalNav}><Link to='/' className='link'>Home</Link></div>
            <div className="pages" onClick={toggleVerticalNav}><Link to='/about' className='link'>About</Link></div>
            <div className="pages" onClick={toggleVerticalNav}><Link to='/services'className='link'>Services</Link></div>
            <div className="pages" onClick={toggleVerticalNav}><Link to='/course' className='link'>Course</Link></div>
            <div className="pages" onClick={toggleVerticalNav}><Link to='/news' className='link'>News</Link></div>
            
            <Button
              variant="contained"
              className="contacts-button "
              onClick={toggleVerticalNav}
              sx={{
                backgroundColor: '#00a7d8',
                transition: '0.4s ease-in-out',
                '&:hover': {
                  backgroundColor: '#93e3fc',
                  transition: '0.4s ease-in-out',
                  transform: 'scale(1.02)',
                  color: 'white'
                },
              }}
            >
              <Link to='/contact' className='link'>Contact Us</Link>
            </Button>
          </div>
          <div className="navbar-brands">
          <img src={logo}alt="Fewa logo" id="atreya-logo" />
          <span className='logo-name'>Fewa Securities</span>
          <span className='quote'>Strive for excellence</span>
        </div>
        </motion.div>
      )}
    </>
  );
};

export default Navbar;
