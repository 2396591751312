import React, { useEffect } from 'react';
import './Contact.css';
import { FaRegCopy } from 'react-icons/fa';
import Navbar from './Navbar';
import { useSnackbar } from 'notistack';
import emailjs from 'emailjs-com';

const Contact = () => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);

  const phoneNumber = '123-456-7890'; // Replace with your actual phone number
  const email = 'pawslin2@gmail.com'; // Replace with your actual email

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        enqueueSnackbar(`Copied "${textToCopy}" to clipboard.`, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        console.error('Unable to copy text to clipboard', err);
        enqueueSnackbar('Failed to copy text.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_2r8injw', 'template_m4idtaq', e.target, 'JaYaAGBGdONzjXdXM')
      .then((result) => {
        console.log(result.text);
        enqueueSnackbar('Message sent successfully.', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }, (error) => {
        console.log(error.text);
        enqueueSnackbar('Failed to send message.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <>
      <Navbar />
      <div className='contact-container'>
        <div className="contact-title">Contact Us</div>
        <div className='contact'>
          <div className="left-contact">
            <div className="top-left">
              <h1>Your feedback</h1>
              <h2 className='nomargin'>helps us improve</h2>
              <p>We are here to help you and we'd love to hear from you.</p>
              <span>We'll get back within 5h</span>
            </div>
            <div className="bottom-left">
              <div className="contact-info">
                <div className='copy'>
                  <strong>Phone:</strong> {phoneNumber}
                  <button className='copy' onClick={() => handleCopy(phoneNumber)}>
                    <FaRegCopy className='copy'/>
                  </button>
                </div>
                <div className='copy'>
                  <strong>Email:</strong> {email}
                  <button className='copy' onClick={() => handleCopy(email)}>
                    <FaRegCopy className='copy'/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="right-contact">
            <div className="top-right">
              <form onSubmit={sendEmail}>
                <div className='inner'>
                  <span>Title</span>
                  <input type='text' name="title" className='Title' placeholder='' required />
                </div>
                <div className='inner'>
                  <span>Name</span>
                  <input type='text' name="from_name" className='Name' placeholder='' required />
                </div>
                <div className='inner'>
                  <span>Email</span>
                  <input type='email' name="from_email" className='Email' placeholder='' required />
                </div>
                <div className='inner'>
                  <span>Phone</span>
                  <input type='text' name="phone" className='Phone' placeholder='' required />
                </div>
                <div className='inner'>
                  <span>Message</span>
                  <textarea id="Message" name="message" rows="5" cols="50" required />
                </div>
                <button type="submit" className="send-message">Send Message</button>
              </form>
            </div>
          </div>
        </div>
        <div className="contact-title">Visit Us</div>
        <div className="google-map">
          <div>
            <iframe
              loading='lazy'
              width="100%"
              height="600"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20First%20Step%20Pre-School%20Street,%20Lalitpur,%20Nepal+(My%20Business%20First%20Step%20Pre-School)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.maps.ie/population/">Population calculator map</a>
            </iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
