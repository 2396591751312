import React, { useEffect, useRef } from 'react';
import './ServicesHome.css';
import { MdVerified } from "react-icons/md";
import blob1 from '../assets/blob1.png';
import c1 from '../assets/c1.jpg';
import c2 from '../assets/c2.jpg';
import c3 from '../assets/c3.jpg';
import c4 from '../assets/c4.jpg';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const courses = [
  { 
    title: 'Security Basic Training', 
    description: 'This course covers the fundamentals of security, including essential drills, discipline practices, and techniques for effectively dealing with people in various scenarios. Trainees will learn basic security protocols, effective communication skills, and situational awareness to handle routine and emergency situations. The training also includes practical exercises to reinforce the skills required for maintaining safety and order in a variety of environments.',
    image: c1,
  },
  { 
    title: 'Code of Conduct', 
    description: 'In this course, participants will be introduced to the professional standards and ethical guidelines that govern behavior in security roles. The curriculum includes an overview of expected conduct, the importance of maintaining a professional demeanor, and how to address violations of the code. Emphasis is placed on understanding legal obligations, upholding organizational values, and fostering a respectful and safe workplace environment.',
    image: c2
  },
  { 
    title: 'Commencing Your Duty', 
    description: 'This course provides a comprehensive introduction to the processes and responsibilities associated with starting a security shift. Participants will learn about initial procedures, including shift handover protocols, briefing on current security issues, and setting up for effective duty. The training focuses on the importance of preparation, communication with colleagues, and understanding the scope of responsibilities for a successful start to each shift.',
    image: c3
  },
  { 
    title: 'Search Procedure', 
    description: 'The Search Procedure course trains participants on the methods and techniques for conducting thorough searches of people, baggage, and vehicles. Topics include legal considerations, effective search strategies, and maintaining privacy and respect during searches. Trainees will practice techniques for ensuring security while balancing efficiency and thoroughness, preparing them for a variety of search scenarios in both routine and high-stakes environments.',
    image: c4
  },
  // Add more courses as needed
];

const ServicesHome = () => {
  const containerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const animateBlobs = () => {
      const blobs = containerRef.current.querySelectorAll('.blob');
      blobs.forEach(blob => {
        blob.style.top = `${Math.random() * 100}vh`;
        blob.style.left = `${Math.random() * 100}vw`;
        blob.style.animation = `moveBlob ${Math.random() * 5 + 5}s infinite`;
      });
    };

    animateBlobs();
    window.addEventListener('resize', animateBlobs);

    return () => {
      window.removeEventListener('resize', animateBlobs);
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 600, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function for animation
      once: false, // Whether animation should happen only once
    });
  }, []);

  return (
    <div className='main-container' ref={containerRef}>
      <img src={blob1} alt="" className='blob'/>
      <img src={blob1} alt="" className='blob1'/>
      <img src={blob1} alt="" className='blob2'/>
      <img src={blob1} alt="" className='blob3'/>
      <img src={blob1} alt="" className='blob4'/>
      <img src={blob1} alt="" className='blob5'/>
      <div className="course-home-heading" data-aos="fade-up">
        <h2 className='title-course'>Our Courses 📖</h2>
        <p className='c-desc'>We offer a wide range of courses to help you develop <br /> your skills and knowledge in security.</p>
      </div>
      
      <div className="services-home-container" data-aos="fade-up">
        {courses.map((course, index) => (
          <div className="services-home-content" key={index}  data-aos-delay={`${index * 100}`}>
            <div className="animated-border">
              <div className="services-home-img" >
                <img src={course.image} alt={course.title} className='image' />
              </div>
              <div className="top-container">
                <div className="course-title">
                  {course.title}
                </div>
                <div className="verification">
                  <MdVerified className='veri'/>
                </div>
              </div>
              <div className="texting">{course.description || 'Description not provided.'}</div>
              <div className="more-button" onClick={() => navigate('/course')}>
                More Info
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="buttons" onClick={() => navigate('/course')}>
        More Courses
      </div>
    </div>
  );
}

export default ServicesHome;
