import React from 'react'
import './Footer.css'
import logo from '../assets/logo.png'
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa'

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className="footer-top">
        <div className="footer-img">
          <img src={logo} alt="Company Logo" />
        </div>
        <div className="footer-links">
          <span className="footer-title">Links</span>
          <div className="footer-link">About Us</div>
          <div className="footer-link">Services</div>
          <div className="footer-link">Gallery</div>
          <div className="footer-link">Members</div>
          <div className="footer-link">Process</div>
          <div className="footer-link">Blog</div>
        </div>
        <div className="footer-links">
          <span className="footer-title">Home</span>
          <div className="footer-link">About Us</div>
          <div className="footer-link">Services</div>
          <div className="footer-link">Courses</div>
          <div className="footer-link">News</div>
          <div className="footer-link">Courses</div>
          <div className="footer-link">News</div>
         
        </div>
        <div className="footer-right">
          <div className="footer-contact">
            <div className="footer-title">Contact Us</div>
            <div className="footer-contact-item">Pokhara</div>
            <div className="footer-contact-item">9766606732</div>
            <div className="footer-contact-item">fewasecurities@gmail.com</div>
          </div>
          <div className="footer-socials">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook className='icon'/>
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram className='icon'/>
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter className='icon'/>
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className='icon'/>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        ©2024 Copyright by Fewa Securities. All rights reserved.
      </div>
    </div>
  )
}

export default Footer
