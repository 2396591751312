import React, { useState, useEffect } from 'react';
import './ServicesHome.css';
import { MdVerified } from "react-icons/md";
import Navbar from './Navbar';
import { AiOutlineSearch } from "react-icons/ai";
import banner from '../assets/banner1.png';
import c1 from '../assets/courses/c1.jpg';
import c2 from '../assets/courses/c2.jpg';
import c3 from '../assets/courses/c3.jpg';
import c4 from '../assets/courses/c4.jpg';
import c5 from '../assets/courses/c5.jpg';
import c6 from '../assets/courses/c6.jpg';
import c7 from '../assets/courses/c7.jpg';
import c8 from '../assets/courses/c8.jpg';
import c9 from '../assets/courses/c9.jpg';
import c10 from '../assets/courses/c10.jpg';
import c11 from '../assets/courses/c11.jpg';
import c12 from '../assets/courses/c12.jpg';
import c13 from '../assets/courses/c13.jpg';
import c14 from '../assets/courses/c14.jpg';

const courses = [
  { 
    title: 'Security Basic Training', 
    description: 'This course covers the fundamentals of security, including essential drills, discipline practices, and techniques for effectively dealing with people in various scenarios. Trainees will learn basic security protocols, effective communication skills, and situational awareness to handle routine and emergency situations. The training also includes practical exercises to reinforce the skills required for maintaining safety and order in a variety of environments.',
    image: c1
  },
  { 
    title: 'Code of Conduct', 
    description: 'In this course, participants will be introduced to the professional standards and ethical guidelines that govern behavior in security roles. The curriculum includes an overview of expected conduct, the importance of maintaining a professional demeanor, and how to address violations of the code. Emphasis is placed on understanding legal obligations, upholding organizational values, and fostering a respectful and safe workplace environment.',
    image: c2
  },
  { 
    title: 'Commencing Your Duty', 
    description: 'This course provides a comprehensive introduction to the processes and responsibilities associated with starting a security shift. Participants will learn about initial procedures, including shift handover protocols, briefing on current security issues, and setting up for effective duty. The training focuses on the importance of preparation, communication with colleagues, and understanding the scope of responsibilities for a successful start to each shift.',
    image: c3
  },
  { 
    title: 'Search Procedure', 
    description: 'The Search Procedure course trains participants on the methods and techniques for conducting thorough searches of people, baggage, and vehicles. Topics include legal considerations, effective search strategies, and maintaining privacy and respect during searches. Trainees will practice techniques for ensuring security while balancing efficiency and thoroughness, preparing them for a variety of search scenarios in both routine and high-stakes environments.',
    image: c4
  },
  { 
    title: 'Gate House Duties', 
    description: 'This course covers the responsibilities and best practices for working at a gate house. It includes training on access control procedures, monitoring and managing visitors, and handling gate operations. Participants will learn how to efficiently manage entry and exit points, maintain security protocols, and interact with the public in a professional and effective manner. Practical exercises simulate real-life gate house situations.',
    image: c5
  },
  { 
    title: 'Guard Alertness', 
    description: 'The Guard Alertness course focuses on techniques for maintaining high levels of vigilance and attention while on duty. Training includes strategies for staying focused, recognizing potential threats, and managing fatigue. Participants will learn methods for enhancing alertness, developing observation skills, and implementing techniques to remain engaged and responsive to security challenges throughout their shift.',
    image: c6
  },
  { 
    title: 'Fire Prevention & Fire Fighting', 
    description: 'This course provides essential knowledge and skills for preventing and responding to fire emergencies. Topics include fire safety protocols, the operation of firefighting equipment, and techniques for fire prevention. Participants will learn how to identify fire hazards, respond to fire alarms, and take appropriate actions during fire emergencies. Hands-on training includes practical fire drills and the use of firefighting tools.',
    image: c7
  },
  { 
    title: 'Emergency Procedure', 
    description: 'Participants in the Emergency Procedure course will learn how to effectively manage various emergency situations, including theft, fire, and bomb threats. The course covers emergency response plans, evacuation procedures, and communication strategies during crises. Trainees will engage in scenario-based training to practice their response skills and develop a clear understanding of their roles and responsibilities during emergencies.',
    image: c8
  },
  { 
    title: 'Threat to Industries', 
    description: 'This course addresses potential threats faced by industrial facilities and teaches participants how to identify, assess, and respond to these threats. Topics include recognizing security risks, implementing preventive measures, and developing contingency plans for various industrial threats. The course prepares trainees to handle security issues specific to industrial settings and to collaborate with other security professionals to safeguard industrial operations.',
    image: c9
  },
  { 
    title: 'Patrolling (Ground Watch)', 
    description: 'The Patrolling course focuses on effective patrolling techniques and ground watch strategies. Participants will learn how to conduct thorough patrols, identify potential security issues, and document observations. The training emphasizes the importance of regular patrols in maintaining security, responding to incidents, and providing a visible security presence. Practical exercises simulate patrolling scenarios and reinforce best practices for ground watch duties.',
    image: c10
  },
  { 
    title: 'Report Writing', 
    description: 'This course teaches participants how to write clear, accurate, and professional security reports. Topics include report structure, documentation techniques, and the importance of detailed reporting for security incidents. Participants will practice writing various types of reports, including incident reports, daily logs, and observations. The course focuses on developing writing skills that are essential for effective communication in security roles.',
    image: c11
  },
  { 
    title: 'Health & Safety', 
    description: 'The Health & Safety course covers key principles and practices for maintaining a safe and healthy work environment. Topics include occupational health risks, safety regulations, and best practices for preventing accidents and injuries. Participants will learn about safety protocols, emergency response procedures, and how to promote health and safety in the workplace. The course includes practical exercises and case studies for real-world application.',
    image: c12
  },
  { 
    title: 'First Aid', 
    description: 'In this First Aid course, participants will learn essential first aid skills for providing immediate care in emergency situations. Topics include basic first aid techniques, CPR, and the management of common injuries and illnesses. The training covers emergency response procedures, the use of first aid supplies, and how to assess and treat various medical conditions. Hands-on practice includes first aid simulations and scenarios.',
    image: c13
  },
  { 
    title: 'Electronic Security System', 
    description: 'This course introduces participants to electronic security systems, including CCTV, digital door locks, and video door phones. The training covers system installation, operation, and maintenance, as well as best practices for using electronic security technologies. Participants will gain practical skills in setting up and managing electronic security systems, as well as understanding their role in overall security strategies.',
    image: c14
  }
  // Add more courses as needed
];

const Course = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  const openModal = (course) => {
    setSelectedCourse(course);
  };

  const closeModal = () => {
    setSelectedCourse(null);
  };

  const filteredCourses = courses.filter(course =>
    course.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    course.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <div className="banner-container">
        <img src={banner} alt="Banner" />
      </div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search for courses..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar"
        />
        <AiOutlineSearch className="search-icon" />  {/* Add the search icon */}
      </div>
      
      <div className="services-home-container white">
        {filteredCourses.length > 0 ? (
          filteredCourses.map((course, index) => (
            <div className="services-home-content" key={index}>
              <div className="animated-border">
                <div className="services-home-img">
                  <img src={course.image} alt={course.title} className='image' />
                </div>
                <div className="top-container1">
                  <div 
                    className="services-title-container" 
                    onClick={() => openModal(course)}
                    style={{ cursor: 'pointer' }}
                  >
                    <h3 className="services-home-title">
                      {course.title}
                    </h3>
                    <MdVerified className="verified-icon" />
                  </div>
                  <div className="services-home-description">
                    <p className='description'>{course.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No courses found..</p>
        )}
      </div>

     
    </>
  );
};

export default Course;
