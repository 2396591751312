import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Companies.css';

import c1 from '../assets/company1.png';
import c2 from '../assets/company2.png';

import c3 from '../assets/company3.png';

import c4 from '../assets/company4.png';

import c5 from '../assets/company5.png';
import c6 from '../assets/company6.png';
import c7 from '../assets/company7.png';


const Companies = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  let carouselRef = null;

  return (
    <div className="company-container">
      <div className="company-top">
        
       
        
      </div>
      <div className="company-bottom">
        <div className="company-line">
        <Carousel
          ref={(el) => (carouselRef = el)}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2000}
          arrows={false} // Disable default arrows
        >
          <div className="logo-item"><img src={c1} alt="Company 1" /></div>
          <div className="logo-item"><img src={c2} alt="Company 2" /></div>
          <div className="logo-item"><img src={c3} alt="Company 3" /></div>
          <div className="logo-item"><img src={c4} alt="Company 4" /></div>
          <div className="logo-item"><img src={c5} alt="Company 5" /></div>
          <div className="logo-item"><img src={c6} alt="Company 6" /></div>
          <div className="logo-item"><img src={c7} alt="Company 7" /></div>
        </Carousel>
      </div>
      </div>
    </div>
  );
}

export default Companies;
