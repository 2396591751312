import React from 'react'
import './Info.css'
const Info = () => {
  return (
    <div className='info-container'>
        <div className="info-content">
            <div className="info-estd">
                Providing Services Since
            </div>
            <div className="info-year">
                2062 B.S
            </div>
            <div className="companies-work">
                Companies we work with
            </div>
        </div>
    </div>
  )
}

export default Info